@charset "utf-8";

$pricepadding = 0.7em
$themecolor = #830
$themelight = #940
$themedark = #720
$fontfamily =  Oswald, Helvetica, Arial, sans-serif
$seccolor = #9b3
$seccolordark = #9b3
$hdrcolor = #720
$textcolor = #000
$textinv= #fff


.row, .clearfix
  width 100%
  margin 0 auto
  overflow hidden

/*div
  outline 1px solid red
*/

html, body
   height 100%


body
  padding 0
  margin 0
  font-family "Segoe UI",Helvetica, Arial, sans-serif
  font-size 10pt
  color #000
  min-width 450px

  *
    box-sizing border-box
    margin 0
    padding 0
  *::selection
    background $themecolor
    color #fff
  *::-moz-selection
    background $themecolor
    color #fff

.o
  outline 1px dashed #666

.o1
  outline 2px dashed #f00

a
  color inherit
  &.buyButton
    display block
    width 90%
    background 1em 50% transparent no-repeat
    background-position 4em 50%
    padding $pricepadding 0
    text-transform uppercase
    color #fff
    text-indent 0
    transition 0.3s all
    border-radius 0.5em
    margin-left 0
    text-indent 0em
    background-color #fff
    border 1px solid $themecolor
    color $themecolor
    text-decoration none
    font-size 80%
    &:hover
      background url("images/icons/basketbtn.png") 1em 50% transparent no-repeat
      background-position 1.3em 50%
      background-color $themecolor
      border 1px solid transparent
      color #fff
      text-indent 2em

header
  font-style normal
  font-weight 400
  max-width 1500px
  margin auto
  position relative
  background-color #442E20


.toppanel
  background -webkit-linear-gradient(#442E20, $themecolor)
  background -o-linear-gradient(#442E20, $themecolor)
  background -moz-linear-gradient(#442E20, $themecolor)
  background linear-gradient(#442E20, $themecolor)



.half
  display block
  float left
  width 50%
  margin 0
  text-align center
  display block
  float left
  width 50%
  margin 0
  text-align center

.third
  display block
  float left
  width 33.3%

.quarter
  display block
  float left
  width 25%

.logo
  text-align left
  padding 0.9em 1em .9em 2em
  float left
  width 20%
  a
    display block
    width 100%
    img
      width 100%

.headerLinks
  padding 0 0 0 0
  float right

  a
    text-decoration none
    padding-left 0
    font-size 100%
    margin 0 0
  li
    display block
    float left
    display block
    float left
    padding
    margin 0 1em

#basketBlock
  background url("images/icons/basket.png") 0 50% no-repeat
  position relative
  padding-top .4em
  float right
  margin-right 2em
  a
    text-decoration none
    display block
  .prodKs
    position absolute
    display block
    background-color #F08D0E
    padding 0.4em 0.7em
    border-radius 8em
    font-weight bolder
    color #FFF
    left 15px
    font-size 76%
    top 17px
    float left

#uplinkarea
   text-align:right
   padding 0em 0
   margin-top 1em
   float right
   width 76%
   font-size 150%


#toplinks
  color black
  text-align right
  .up_mail
    padding 0.2em 2em 0.2em 3em
    background url("images/icons/topmail.png") 0 50% transparent no-repeat
  .up_kontakt
    padding 0.2em 1em 0.2em 3em
    background url("images/icons/toptel.png") 0 50% transparent no-repeat

.uptoplinks
  padding-top 1em

.linkicon
  display inline
  line-height 3em
  padding 0.2em

.basketWrapper
  padding-top 0em
  width 100%

.vKosiku
  line-height 4em
  font-size 130%
  padding-left 3em
  color #fff
  font-weight bolder

.frontpage
  .toppanel
    background-color $themecolor
    margin-bottom 0
  .topmenu
    a
      color #fff
      padding 0 0 0 0
      margin 0 1em
      text-decoration none
      border-bottom 2px solid transparent
      text-transform: uppercase
      font-size 90%
      transition .3s all
      &:hover, &.active
        border-bottom 2px solid orange

#content
   padding 1em
   min-height:35em;

.burger
  color white
  display none
  float left
  padding-top 2.3em


.detailpage
  .toppanel
    background-color #81563b
    background -webkit-linear-gradient(#442E20, #81563b)
    background -o-linear-gradient(#442E20, #81563b)
    background -moz-linear-gradient(#442E20, #81563b)
    background linear-gradient(#442E20, #81563b)
    margin-bottom 1em
  .topmenu
    a
      color #fff

  .mainContent
    padding 0 3em !important
  .mainproduct
    h1
      margin-bottom 0

.topmenu
  text-transform none
  text-align right
  font-size: 100%;
  padding-top: .2em;
  li
    display inline
  a
    &.active
      font-weight bold

.mainwrap
  max-width 1500px
  margin auto
  padding-top 0  /*musi byt 0 kvuli zarovnani stranky*/
.dynapanel
  font-family Segoe UI
  color #fff
  position relative
  width 100%
  padding 0
  background-color white
  img
    display block
    width 100%
    height unset
    max-width 100%
  &.panel1
    .obrazek
      position absolute
      left 50%
      top 72px
      opacity 0
      transition 1s 0.7s all
    .levytext
      position absolute
      top 20%
      left 40px
    .banner_bighdr
      margin-bottom 1em
      height 118px
      img
        position absolute
        left 4em
        opacity 0
        transition 1s 1s all
    .banner_smallhdr
      font-size 250%
      font-weight lighter
      font-style italic
      text-transform uppercase
      position relative
      left 10em
      opacity 0
      transition 1s 0.5s all
    .banner_bigtext
      font-family Segoe UI Black
      font-size 110pt
      font-style italic
    .banner_exttext
      font-size 20pt
      font-style italic
      font-weight light
    .pravytext
      font-size 500%
      position absolute
      right 5%
      font-style italic
      top 65%
      font-weight bold
      opacity 0
      font-size 500%
      margin-left -2em
      transition 0.5s 1.8s all
    .hdr_link
      padding 1em 0
      opacity 0
      transition 0.5s 1.1s all
      a
        border 1px solid #fff
        border-radius 3em
        text-transform uppercase
        display block
        text-align center
        margin 0 auto
        padding 1.2em 0
        width 15em
        transition 0.5s all
        text-decoration none
    &.trig
      .banner_bighdr
        img
          left 0
          opacity 1
      .banner_smallhdr
        left 0px
        opacity 1
      .obrazek
        opacity 1
      .pravytext
        opacity 1
        font-size 500%
        margin-left 0
      .hdr_link
        opacity 1

.banner_text
  margin-bottom 3em

.hdr_link
  a
    &:hover
      background-color #fff
      color #000
      text-decoration inherit

#content
  .sidebar
    float left
    width 25%
    text-align left
  .mainContent
    float left
    width 75%
    font-weight 500
    .productRow
      overflow auto
      color #929292
  .sidebar.right
    float right
    width 25%
    text-align left

#content .mainContent th
  color white
  font-family $fontfamily
  font-weight 300

#content .productinfo th
  color black
  font-family $fontfamily
  font-weight 300

#content .mainContent h1, #content .mainContent h2, #content .mainContent h3
  font-family $fontfamily
  font-weight 300

#content .mainContent h1
  font-size 200%
  text-transform uppercase
  color $textcolor

.menu
  margin-bottom 1em
  padding 0.5em 1em
  ul
    li
      list-style-type none
      margin-left -1.2em
      position relative
      &::before
        /*background url(css/unibullet1.svg) 0 0 no-repeat;*/
        width: 1em;
        height: 1em;
        position: absolute;
        content: ''
        top 0.2em
      &:hover
        &::before
          /*background url(css/unibullet2.svg) 0 0 no-repeat;*/


  ul ul
         li
            margin-left:1em

.menubar
  h2
    margin 0 1em 1em 0
    font-size 150%
    font-family $fontfamily
    font-weight lighter
  ul
    padding 0
    margin 0
    ul
      padding 0 1em
  li
    padding 0
  a
    display block
    padding 0 0 1em 1.5em
    color #000
    text-decoration none

.breadcrumb
  font-family $fontfamily
  color #5C3E2B
  padding-left 1.2em
  .wrapper
    padding 1em 1em 0.4em 1em
    margin-bottom 0
.sitesearch
   position relative
   .searchbox
     width 100%
     margin 0 0
     box-sizing border-box
     padding 0.5em 3em .5em 0.2em
     font-size 100%
     background-color #d5d5d5
     border 0
     position relative
   .searchbtn
      position absolute
      right 0
      top 0
      bottom 0
      border 0
      background url("images/icons/lupa.png") 0 50% no-repeat
      width 3em
      cursor pointer

.menubar a.active,
.menubar a:hover
  background url("images/icons/bullet.png") 0 0 no-repeat

.menubanner
  img
    display block
    margin auto
    max-width 100%
    height unset

/*  display flex;
  flex-flow row wrap*/

.prodcategs
  .wysiwtext
    padding 0 0
    font-size 120%
  h2
    font-size 180%
    border-color inherit
    font-weight 500
    text-transform uppercase
    margin .5em 1em 1em 1em
  a
    text-decoration none
  li
    list-style outside none none
    display inline
    margin-right 1em
    margin-bottom .8em
    color #000
    a
      &:hover
        text-decoration underline
  ul
    margin-bottom 2em
  .prodlist
      .linkcatg
         text-align center
         text-transform uppercase
         margin-bottom .7em
         padding .7em
         font-size 90%
         a
            border: 1px solid $666
            padding .2em 1em
            background white
            color black
            font-weight bold
            transition all .4s
            border-radius: 1em
            &:hover
              background $seccolor;
              border: 1px solid $seccolor
              color white
              box-shadow: 0 0 0 1em $seccolor inset;
              font-size:100%

      h3
         min-height 3.5em
    .wrapper
      border 1px solid #999



.productgroup
  margin-bottom 1em
  .grpmenu
    color #000
    border-color #00b7be
    padding 0 //1em 1.2em
  .wrap
    padding 1em
    h1
      font-size 180%
      border-color inherit
      font-weight 500
      text-transform uppercase
      margin .5em 1em 1em 0.5em
  .catlinks
    margin-bottom 1.2em
    font-size 120%
    a
      margin-right 5em
      text-transform uppercase
      text-decoration none

.productbig
  padding 1em 2em 2em 2em

.prodlist
  .wrapper
      box-shadow 0 0 .4em rgba(0,0,0,.4)
      padding .5em
  p
    font-size 100%
  h3
    color #000
    text-transform uppercase
    font-family $fontfamily
    margin-bottom 1em
    text-align center

.prodlist,
.footerblock
   text-align left
   width 30.3%
   text-align left
   float left
   margin:0 1.5%

.col4 .prodlist,
.col4 .footerblock
  width 22%

.col3 .prodlist,
.col3 .footerblock
  width 30.333%

.contentExt,
.footerCont
  padding 0 2em

.contentExt
   .prodlist
      float left


.footerblock
  .wrapper
    padding 1em 2em
    text-align left
    font-size 100%
    line-height 1.7em
    color #eee
  ul
    padding 0
    margin 0
    li
      list-style none
  h3
    margin-bottom 0.6em
    font-weight normal
    padding-bottom 0.3em
    text-transform uppercase
    font-size 150%
    border-bottom 1px solid $themecolor
    color rgb(240, 234, 234)
  h4
   margin-bottom 0.6em
  .hdrspacer
    height 3em
  p
   line-height 1.4em
   margin-bottom .7em
   font-size 80%

.row
/*  margin-bottom 1.6em*/

h2
  .cathdr
    font-size 180%
    border-bottom 1px solid #000
    border-color inherit
    font-weight 500
    text-transform uppercase
    margin-bottom 0.9em

.seenHdr
  position relative
  border-bottom 1px solid #ddd
  h2
    text-align center
    width 10em
    margin auto
    border-style solid
    border-radius 3em
    padding 0.5em
    font-weight 200
    font-family $fontfamily
    position relative
    bottom -1em
    background-color #fff
    border-width 1px
    border-color #ddd
    border 1px solid #ddd
    text-transform uppercase

.smimage
  a
    display block
    .prodimg_small
      margin-bottom 2em
  img
    display block
    margin auto
/*    width 100%
    max-width 200px
    height unset*/

.txtcomment
  margin-bottom 1em
  color #000
  line-height 1.3em
  font-weight lighter
  font-size 100%
  text-align center

.price
  line-height 1.6em
  color $themecolor
  font-size 100%
  font-weight bold
  padding $pricepadding

.articleFrontBlock
  .prodlist
    h3
      text-align center
      font-size 180%
      font-weight lighter
      margin-bottom 1em

.wideWrapper
  background-color #eee
  margin auto
  &.novinky
   background-color white

.widefooter
  background-color #000
  color #fff

.mainContent
  .productRow
    .productInfo
      float left
      padding-left 6%
      padding-right 5%
      width 22%

.productRow
  .productInfo
      .price
         color #929292
         font-size 22px
         position relative
         top -20px
       .productContent
         position relative
         top -37px
         font-size 14px
         font-style normal
         font-weight 200
         color #929292
         white-space pre-wrap
       .buyButton
         position relative
         top -48px
         width 84%
         background-color #d0cfcf
         height 35px
         color #fff
         border-style none
         font-size 14px
         text-transform uppercase
         margin-top 9px

.mainproduct
  .wrapper
    padding 0 1em 0em .1em
  h1
    font-size 220%
    font-weight lighter
    margin-bottom .7em
  h2
    margin-bottom .7em
    font-weight lighter
    font-size 150%
  .articletext
    text-align left
    margin-bottom 2em
  .row
    margin-bottom 0

.valuestable td,
.valuestable th
  text-align left
  padding 0.3em 2.5em 0.3em 0

.valuestable
  margin-bottom 2em
  th
    padding 0.3em 2.5em 0.3em 0

.cms_chart
  padding 1em 1em 1em 0
  margin-bottom 2em

.cms_chart_label
  text-align left
  padding 0.2em 2em 0.2em 0

.cms_spark_out
  display block
  width 6em
  overflow hidden
  text-indent -200px
  background #eee
  height 0.8em

.cms_spark
  display block
  overflow hidden
  text-indent -200px
  height 6px
  height inherit
  background #777
  &.val0
    width 0%
  &.val10
    width 10%
  &.val20
    width 20%
  &.val30
    width 30%
  &.val40
    width 40%
  &.val50
    width 50%
  &.val60
    width 60%
  &.val70
    width 70%
  &.val80
    width 80%
  &.val90
    width 90%
  &.val100
    width 100%

.cms_table_row
  &.row1
    td
      padding-bottom 0.4em

.shoplines
  width 100%
  border 0px solid #000
  font-size 90%

.shopline .colors,
.shopline .desc,
.shopline .price,
.shopline .ksinput,
.shopline .kosikbtn
  overflow auto
  text-transform uppercase

.colors .blk,
.colors .blu,
.colors .yel,
.colors .gry
  display block
  position absolute

.shopline
  .colorcolm
    width 5%
    position relative
  .desc
    font-size 100%
    line-height 3em
    width:30%
  .kosikbtn
    width 35%
  .colors
    height 3em
    width 3em
    background-color #000
    border-top 1.5em solid #f00

.inputbasket
  width 4em
  padding 0.2em 0
  font-size 130%
  border 2px solid #000

.buyButton
  &.tobasket
    text-align center
    background-color $themecolor
    color #fff
    border-color #fff

.bottommenu
  text-align center
  padding:1em 0
  background-color #1B1B1B
  ul
    width auto
  li
    display:inline
    padding: 0 2em


.footerblock
   li
      line-height 200%
  h3
      font-family $fontfamily
      color #F5E3CD

.novinky
  .prodlist
    h3
      text-align center

.articleimgbox
  .imggallery
    display none
    &.cms_gal_img0
      display block

.imagebox
  margin-bottom 2em
  position relative
  .label_new,
  .label_akce,
  .label_shipping
      position absolute
      width 5em
  .label_new
    left 0
    top 0
  .label_akce
    right 0
    top 0
  .label_shipping
    right 0
    bottom 0

.inputline
   label
     width 40%
     display block
     float left
.nlradio, .inputline
    padding 0.6em 0px
    margin-bottom 0px



.shopitems
  padding 0 0em

.prodlist
   border-radius 0em
   margin-bottom 3em
   .smimage
      min-height 180px
      background white
      margin-bottom 1em
      position relative
      overflow hidden
      .label_new,
      .label_akce,
      .label_shipping
         position absolute
         width 5em
         img
            width 100%

      .label_new
         left 0
         top 0
      .label_akce
         right 0
         top 0
         img
      .label_shipping
         right 0
         bottom 0
   .txtcomment
      height 4em
      overflow hidden

.novinky, .contentExt
   .smimage
      height auto

table.productinfo
   width 100%
   background #f6f6f6
   margin-bottom 2em
   border-collapse collapse
   td, th
      padding .6em .2em

.productText
   color black
   font-size 100%
   margin-bottom 2em
   font-size 90%
   line-height 1.5em
   ul
      padding-left 1em
      margin-bottom 1.5em;
      li
         list-style: inside initial !important
         margin-bottom: 0.3em
.galerie
   .imggallery
      float left
      margin-right 3em

.shopline.row td
   white-space nowrap

.searchresult
   margin-bottom 1em
   a
      text-decoration underline

.founditems
   padding 1em 0


.cms_taglist
   li
      padding 0 .5em
      word-wrap none
      display inline
      font-size 85%
      background-color:#f4f4f4
      border-radius 0em
      padding .3em 1em
      line-height 2.6em
      position relative
      margin-right 1em
      a
         color:maroon
         white-space nowrap
         text-decoration none
         span
            color black
      a:hover
         color maroon
         text-decoration underline
      .badge
        position: absolute;
        right: -2em;
        bottom: 0.9em;
        background-color: orange;
        border-radius: 2em;
        padding: 0;
        min-width: 2.5em;
        text-align: center;
        font-size: 70%;
        padding: 0;
        margin: 0;
        display:none
   li::after
      content ' '

.menuItemTx
   p
      margin-bottom 1em
.textpage
  h1
      margin-bottom 1em
      border-bottom 1px solid black


.textpage       //mapa stranek
  ul.sitemap, ul.sitemap ul
    margin 0
    padding 0
    margin-left 2em
    li
      display inline
      margin 0
      padding 0
      a
        display block
        margin-right 1em
      a.dir
        font-size 120%
        display block
        padding-top 0.7em
        padding-bottom 0.2em
      ul
        margin-left 2em


.kosik
   h1
      margin-bottom .9em

.textcateg
  padding 0 1em
  h3
    display block
    margin 0 0 1em 0
    color #c87005
  p
    display block
    margin 0 0 1em 0
  ul
    display block
    padding-left 1em
  li
    display block
    padding-left 1em
    list-style disc
  ol
    counter-reset: item;
  ol li
    display: block;
  ol:before
    counter-increment: item;
    content: counters(item, ".") ". ";

.shopmessage
   padding: 2em 1em;
   margin-bottom: 1em
   border-radius: 0.3em
   color: black;
   background-color: #eee
   &.basketempty
      border: 0
      box-shadow: none;
      color:black

.formpair
   padding .5em 0
   label
      display block;
      float left;
      width 25%
   .textfield
      padding .3em

.formpairw
   padding 0 0
   label
      display block;
      width 100%
   .textfield
      padding .3em

.sidemenu
   ul
      padding 1em
      background-color #EDDACF
      margin 0 1em 1em 1em;
      li
         display inline
         margin-right 2em
         .active
            font-weight bold
         a
            text-decoration none




@media screen and (max-width: 1200px)
  .prodlist
    width 47%
    margin 1.5%
  .logo
    width 13em
  #uplinkarea
    font-size 120%
    margin 1em
  .frontpage .topmenu a
    margin 0 .5em

@media screen and (max-width: 960px)
      body
         font-size 85%
         background #fff
      .topmenu
        display none
      #uplinkarea
         display none
      .burger
         display block
         color white
         &.menuopen
            color white
      .mainwrap
         padding-top 0
      header
         position fixed
         width 100%
         z-index 1000
      .topwrap
         display: none;
      #toplinkarea
         .logo
           margin-left 2.5em
           min-width:10em !important
      .contentExt, .footerCont
         padding: 0 0em;
      #content
        padding-top 7em
        .mainContent
          width 100%
        .sidebar
          display none
          float none
          left 0
        .sidebar
            position fixed
            left -30em
            top 0
            bottom 0
            z-index 10
            width 23em
            overflow auto
            height 100%
            display block
            background #eee
            transition all .4s
            &.menuopen
               display block
               left 0
               box-shadow 0 0 1em black
            .menu.mainproducts
              padding-top 0em
        .menubar
            min-height 100%
            padding-top 3em
            ul
              margin-bottom 0
              padding-left:1em
      .price
         font-size 100%
      .prodcategs .prodlist h3,
      .prodlist .txtcomment
         margin-bottom 1em
         min-height 0 !important
         height auto !important
      .footerblock h3
         background-color: #830;
         padding: .5em;
      .product-detail
         .row .half
            width 100%
       .prodlist .smimage .label_shipping img
         width auto
      .footerCont
         .footerblock
            width 100%
            font-size 100%
      .prodlist
         margin 0 0 2em 0
         float none
         width 100%
      a.buyButton
         padding: 1em 0
      .novinky
         .prodlist
            margin 0
            float none
            width 50%
            float left
            margin-bottom 0
            .wrapper
               box-shadow 0 0 1em black




